import { ICar, ICarCreateBody } from '@/types';
import { NetworkService } from '.';
import { IOptions } from './network';

class FleetService {
  public async index(page = 1, query = '') {
    return await NetworkService.get(`/fleet/cars?page=${page}&query=${query}`);
  }

  public async get(id: string, options?: IOptions | undefined) {
    return await NetworkService.get(`/fleet/cars/${id}`, options);
  }

  public async create(body: ICarCreateBody) {
    return await NetworkService.post('/fleet/cars', body);
  }

  public async update(body: Partial<ICar>, id: number | string) {
    return await NetworkService.put(`/fleet/cars/${id}`, body);
  }
}
export default new FleetService();
