import config from '@/config';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { GetServerSidePropsContext } from 'next';
import { AuthService } from '.';
import * as Sentry from '@sentry/nextjs'

export interface IUploadSnapshot {
  lengthComputable: boolean;
  loaded: number;
  total: number;
}

const MESSAGES = {
  OFFLINE: 'You are offline, Please connect internet',
  NOT_REACHABLE: 'We are not able to connect to server at the moment',
  UNKNOWN: 'Something went wrong, Please contact to administrator',
  REQUEST_CANCELLED: 'request canceled by user',
  SESSION_EXPIRED: 'Session expired',
};

export interface IOptions {
  external?: boolean;
  headers?: { [key: string]: string };
  onUploadProgress?: (snapshot: IUploadSnapshot) => void;
}

class NetworkService {
  private getAxios() {
    const configuredAxios = axios.create({
      baseURL: `${config.BACKEND_API}/v1`,
    })

    configuredAxios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          AuthService.clearToken();
        }

        Sentry.captureException(error);
        return Promise.reject(error);
      },
    );

    return configuredAxios;
  }

  private handleResponse<T>(response: AxiosResponse<T>) {
    if (response.status === 401) {
      AuthService.clearToken();

      throw new NetworkError(MESSAGES.SESSION_EXPIRED);
    }

    if (response.status !== 200 && response.status !== 201) {
      throw new NetworkError(MESSAGES.UNKNOWN);
    }

    return response;
  }

  private async getHeader(options?: IOptions) {
    const header: { [key: string]: string } = {};

    if (options && options.headers) {
      Object.assign(header, options.headers);
    }

    if (options && options.external) {
      return header;
    }
    let token = '';
    try {
      token = AuthService.getToken() || '';
    } catch (err) {}

    if (token) {
      header['Authorization'] = 'Bearer ' + token;
    }

    return header;
  }

  async get<T = any>(
    url: string,
    options?: IOptions,
  ) {
    const response = await this.getAxios().get<T>(
      url,
      {
        headers: await this.getHeader(options),
      }
    );
    return this.handleResponse(response);
  }

  async post<T = any, D = any>(url: string, data: D, options?: IOptions) {
    const response = await this.getAxios().post<T>(
      url,
      data,
      {
        headers: await this.getHeader(options),
        onUploadProgress: progressEvent => {
          if (options && options.onUploadProgress) {
            options.onUploadProgress({
              lengthComputable: progressEvent.lengthComputable,
              loaded: progressEvent.loaded,
              total: progressEvent.total,
            });
          }
        },
      }
    );
    return this.handleResponse(response);
  }

  async patch<T = any, D = any>(
    url: string,
    data: D,
    options?: IOptions,
  ) {
    const response = await this.getAxios().patch<T>(
      url,
      data,
      {
        headers: await this.getHeader(options),
        onUploadProgress: progressEvent => {
          if (options && options.onUploadProgress) {
            options.onUploadProgress({
              lengthComputable: progressEvent.lengthComputable,
              loaded: progressEvent.loaded,
              total: progressEvent.total,
            });
          }
        },
      }
    );
    return this.handleResponse(response);
  }

  async put<T = any, D = any>(
    url: string,
    data: D,
    options?: IOptions,
  ) {
    const response = await this.getAxios().put<T>(
      url,
      data,
      {
        headers: await this.getHeader(options),
        onUploadProgress: progressEvent => {
          if (options && options.onUploadProgress) {
            options.onUploadProgress({
              lengthComputable: progressEvent.lengthComputable,
              loaded: progressEvent.loaded,
              total: progressEvent.total,
            });
          }
        },
      }
    );
    return this.handleResponse(response);
  }

  async delete<T = any, D = any>(
    url: string,
    data?: D,
    options?: IOptions,
  ) {
    const response = await this.getAxios().delete<T>(
      url,
      {
        headers: await this.getHeader(options),
        data,
      }
    );
    return this.handleResponse(response);
  }

  getRequestConfigFromContext(ctx: GetServerSidePropsContext) {
    const token = ctx.req.cookies[AuthService.storage_key];
    return {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
  }
}

class NetworkError extends Error {}

export default new NetworkService();
