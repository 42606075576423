import { ILocation, ILocationCreateBody } from '@/types';
import { NetworkService } from '.';
import { IOptions } from './network';

class LocationService {
  public async index(page = 1) {
    const response = await NetworkService.get(`/locations?page=${page}`);

    return response;
  }

  public async getById(id: string, options?: IOptions | undefined) {
    const response = await NetworkService.get(`/locations/${id}`, options);

    return response;
  }

  public async create(body: ILocationCreateBody) {
    const response = await NetworkService.post('/locations', body);
    return response;
  }

  public async update(body: Partial<ILocation>, id: number) {
    const response = await NetworkService.put(`/locations/${id}`, body);
    return response;
  }

  public async export() {
    return await NetworkService.get('/locations/export');
  }

  public async updatePlans(body: ILocation, id: number) {
    const response = await NetworkService.post(`/locations/${id}/plans`, {
      location_plans: body
    });
    return response;
  }

  public async carCanWash(carId: string | number) {
    const response = await NetworkService.post(
      `/locations/visits/car_can_wash`,
      { id: carId }
    );
    return response;
  }

  public async createVisit(carId: string | number, locationId: string | number, manual = false) {
    const response = await NetworkService.post(
      `/locations/${locationId}/visits`,
      { id: carId, manual }
    );
    return response;
  }

  public async getVisits(page = 1, locationId: string | number) {
    const response = await NetworkService.get(`/locations/${locationId}/visits?page=${page}`);

    return response;
  }

  public async getPlans(id: number | string) {
    const response = await NetworkService.get(`/locations/${id}/plans`);

    return response;
  }
}

export default new LocationService();
