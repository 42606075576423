import { ILocation, ILocationCreateBody } from '@/types';
import { NetworkService } from '.';
import { IOptions } from './network';

class VisitService {
  public async index(page = 1) {
    return await NetworkService.get(`/admin/location_visits?page=${page}`);
  }

  public async getById(id: string, options?: IOptions | undefined) {
    return await NetworkService.get(`/admin/location_visits/${id}`, options);
  }

  public async create(body: ILocationCreateBody) {
    return await NetworkService.post('/admin/location_visits', body);
  }

  public async update(body: Partial<ILocation>, id: number) {
    return await NetworkService.put(`/admin/location_visits/${id}`, body);
  }

  public async import(file) {
    const formData = new FormData();
    formData.append('file', file);

    return await NetworkService.post(
      '/admin/location_visits/import',
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
}

export default new VisitService();
