import { LogoWhite } from '@/icons';

export function LoadingScreen() {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-l from-blue-500 to-blue-700 md:flex-col md:bg-gradient-to-r">
        <div className="items-center justify-center flex-auto mx-4 pt-52 animate-pulse md:flex-1">
          <LogoWhite className="w-full h-24 " />
        </div>
        <div className="items-center justify-center flex-auto md:flex-1">
          <div className="flex flex-row animate-pulse">
            <div className="w-8 h-8 border-white rounded-full border-y-2 animate-spin"></div>
            <div className="pl-8 text-white ">
              <span className="text-3xl">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
