import { ICar, ICarCreateBody } from '@/types';
import { NetworkService } from '.';
import { IOptions } from './network';

class CarService {
  public async index(page = 1, query = '', role = 'member') {
    let response = null;

    if (role === 'admin') {
      response = await NetworkService.get(`/admin/cars?page=${page}&query=${query}`);
    } else {
      response = await NetworkService.get(`/cars?page=${page}&query=${query}`);
    }

    return response;
  }

  public async getListOfCars(options?: IOptions | undefined) {
    return await NetworkService.get('/cars', options);
  }

  public async getCarById(id: string, options?: IOptions | undefined) {
    return await NetworkService.get(`/cars/${id}`, options);
  }

  public async createCar(body: ICarCreateBody) {
    return await NetworkService.post('/cars', body);
  }

  public async update(body: Partial<ICar>, id: number | string) {
    return await NetworkService.put(`/cars/${id}`, body);
  }

  public async export() {
    return await NetworkService.get('/admin/cars/export');
  }

  public async import(file, userId: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', userId.toString());

    return await NetworkService.post(
      '/admin/cars/import',
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
}
export default new CarService();
