import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Services from '@/services';

const usePagedIndex = ({
  id,
  page = 1,
  resource,
  role = 'member'
}: {
  id?: string | number;
  page?: string | number;
  resource: string;
  role?: 'admin' | 'attendant' | 'member';
}) => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [query, setQuery] = useState('');

  const resourceServiceMap = useCallback(
    () => {
      switch (resource) {
        case 'cars':
          return Services.CarService.index(+page, query, role)
        case 'fleet-cars':
          return Services.FleetService.index(+page, query)
        case 'locations':
          return Services.LocationService.index(+page)
        case 'location-visits':
          return Services.VisitService.index(+page)
        case 'users':
          return Services.UserService.index(+page, query, role)
        case 'visits':
          return Services.LocationService.getVisits(+page, id)
        default:
          throw new Error(`Unable to retrieve ${resource} records`)
      }
    }, [page, resource, id, query, role])

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        await resourceServiceMap().then(({ data, headers }) => {
          setRecords(data || []);
          setTotalPages(+headers['total-pages']);
          setCurrentPage(+headers['current-page']);
          setItemsPerPage(+headers['page-items']);
          setTotalCount(+headers['total-count']);
        })

      } catch (error) {
        toast.error((error as any).message);
        setRecords([]);
        setQuery('');
        setTotalPages(0);
        setCurrentPage(0);
        setItemsPerPage(0);
        setTotalCount(0);
      } finally {
        setLoading(false);
      }
    })();
  }, [page, resourceServiceMap]);

  return [
    currentPage,
    itemsPerPage,
    loading,
    query,
    records,
    setQuery,
    totalCount,
    totalPages
  ] as const;
}

export default usePagedIndex;
