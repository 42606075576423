import { NetworkService } from '.';

class UserService {
  public async index() {
    const response = await NetworkService.get(`/admin/stats`);

    return response;
  }
}
export default new UserService();
