import { SVGProps } from 'react';

export function Logo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 171 37"
      fill="none"
      {...props}
    >
      <path
        d="M41.8217 11.9377C41.8217 5.11472 45.7302 1.82959 51.4416 1.82959C57.153 1.82959 61.0176 5.09956 61.0176 11.968V12.2005H56.7719V11.9225C56.7719 7.66702 54.6027 5.93853 51.4416 5.93853C48.2806 5.93853 46.1113 7.68218 46.1113 11.9377V25.5331H41.8217V11.9377Z"
        fill="#333333"
      />
      <path
        d="M66.3919 28.3026H70.6816C71.7223 30.9206 73.9355 32.1387 77.1405 32.1387C81.2983 32.1387 83.9805 30.0817 83.9805 24.8457V21.515C82.5832 24.3655 79.8716 25.9525 76.5298 25.9525C70.9747 25.9525 66.2307 22.354 66.2307 14.2726V2.45117H70.5204V14.2726C70.5204 19.271 73.188 21.793 77.2529 21.793C81.225 21.793 83.9805 19.1801 83.9805 14.2726V2.45117H88.2751V24.7648C88.2751 32.8007 83.3503 36.0252 77.1601 36.0252C71.991 36 67.7013 33.5235 66.3919 28.3026Z"
        fill="#333333"
      />
      <path
        d="M95.6573 13.5246C95.6573 5.81216 100.084 1.84473 106.68 1.84473C113.275 1.84473 117.702 5.76667 117.702 13.5246V25.5331H113.412V13.5246C113.412 8.52618 110.744 5.94356 106.68 5.94356C102.615 5.94356 99.9519 8.51607 99.9519 13.5246V25.5331H95.6573V13.5246Z"
        fill="#333333"
      />
      <path
        d="M123.574 18.8516H127.908C128.001 20.7671 129.716 22.1216 132.833 22.1216C136.087 22.1216 137.758 20.6761 137.758 18.8516C137.758 16.6531 135.408 16.3751 132.608 16.0011C128.724 15.4957 124.024 14.7376 124.024 9.45613C124.024 4.90748 127.444 1.84473 132.789 1.84473C138.134 1.84473 141.417 4.97318 141.505 8.94567H137.259C137.122 7.1717 135.544 5.8627 132.833 5.8627C129.989 5.8627 128.362 7.26267 128.362 9.08718C128.362 11.2806 130.707 11.5131 133.463 11.8871C137.372 12.3925 142.091 13.1506 142.091 18.4574C142.091 23.1273 138.432 26.1648 132.833 26.1648C127.234 26.1648 123.706 23.0566 123.574 18.8516Z"
        fill="#333333"
      />
      <path
        d="M147.06 13.9896C147.06 6.98467 152.029 1.85986 159.074 1.85986C166.119 1.85986 171 6.98467 171 13.9896V15.6271H151.575C152.21 19.7411 155.19 22.0306 159.074 22.0306C161.967 22.0306 163.999 21.0956 165.308 19.0841H170.052C168.333 23.3851 164.268 26.1597 159.074 26.1597C152.029 26.1395 147.06 20.9996 147.06 13.9896ZM166.481 11.6546C165.626 7.96516 162.694 5.95364 159.074 5.95364C155.371 5.95364 152.571 8.01064 151.711 11.6546H166.481Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000127003 9.83508C-0.0392127 15.7685 3.09253 19.8118 9.68824 19.8573L22.8357 19.9583L22.865 15.5209L9.71756 15.4249C5.60867 15.3895 3.9573 13.1354 3.97684 9.86541C3.99639 6.59544 5.68196 4.36154 9.79573 4.39187H10.2012V-9.92596e-05H9.86901C3.22933 -0.0455857 0.0389587 3.90163 -0.000127003 9.83508ZM10.2012 23.3598V27.7973L20.2804 27.7013C26.8713 27.6558 30.0079 23.5671 29.9688 17.6791C29.9297 11.7911 26.7394 7.79842 20.0997 7.84391H10.2012V12.2359H20.1778C24.2867 12.2056 25.9772 14.3687 25.9967 17.6892C26.0163 21.0097 24.36 23.2183 20.2511 23.2487L10.2012 23.3598Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="33.7645"
          y1="7.50008"
          x2="-3.99428"
          y2="8.20308"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0520833" stopColor="#1A00E2" />
          <stop offset="1" stopColor="#8D7EFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function LogoWhite(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 195 40"
      fill="none"
      {...props}
    >
      <path
        d="M47.5315 13.0071C47.5315 5.57279 51.9737 1.99329 58.4649 1.99329C64.956 1.99329 69.3482 5.55626 69.3482 13.0402V13.2935H64.5229V12.9906C64.5229 8.35378 62.0575 6.47041 58.4649 6.47041C54.8722 6.47041 52.4068 8.3703 52.4068 13.0071V27.8207H47.5315V13.0071Z"
        fill="white"
      />
      <path
        d="M75.4562 30.8386H80.3315C81.5142 33.6912 84.0296 35.0183 87.6722 35.0183C92.3976 35.0183 95.4461 32.777 95.4461 27.0718V23.4428C93.858 26.5487 90.7762 28.2778 86.9781 28.2778C80.6647 28.2778 75.2729 24.3569 75.2729 15.5513V2.67065H80.1483V15.5513C80.1483 20.9977 83.1801 23.7456 87.7999 23.7456C92.3143 23.7456 95.4461 20.8986 95.4461 15.5513V2.67065H100.327V26.9837C100.327 35.7397 94.7298 39.2531 87.6944 39.2531C81.8196 39.2256 76.9443 36.5272 75.4562 30.8386Z"
        fill="white"
      />
      <path
        d="M108.717 14.7364C108.717 6.33282 113.748 2.00989 121.244 2.00989C128.74 2.00989 133.771 6.28326 133.771 14.7364V27.8208H128.896V14.7364C128.896 9.29004 125.864 6.476 121.244 6.476C116.624 6.476 113.598 9.27903 113.598 14.7364V27.8208H108.717V14.7364Z"
        fill="white"
      />
      <path
        d="M140.446 20.5407H145.371C145.476 22.6278 147.425 24.1037 150.968 24.1037C154.666 24.1037 156.565 22.5287 156.565 20.5407C156.565 18.1452 153.894 17.8423 150.713 17.4348C146.298 16.8841 140.956 16.058 140.956 10.3033C140.956 5.34708 144.843 2.00989 150.918 2.00989C156.993 2.00989 160.724 5.41867 160.824 9.74711H155.999C155.843 7.81418 154.05 6.38789 150.968 6.38789C147.736 6.38789 145.887 7.91331 145.887 9.90131C145.887 12.2913 148.553 12.5446 151.684 12.9521C156.127 13.5028 161.49 14.3289 161.49 20.1111C161.49 25.1995 157.331 28.5092 150.968 28.5092C144.605 28.5092 140.595 25.1224 140.446 20.5407Z"
        fill="white"
      />
      <path
        d="M167.138 15.243C167.138 7.61039 172.785 2.02637 180.792 2.02637C188.799 2.02637 194.346 7.61039 194.346 15.243V17.0272H172.268C172.99 21.5099 176.377 24.0045 180.792 24.0045C184.079 24.0045 186.389 22.9857 187.877 20.794H193.269C191.314 25.4803 186.694 28.5036 180.792 28.5036C172.785 28.4816 167.138 22.8811 167.138 15.243ZM189.21 12.6988C188.238 8.67873 184.906 6.48697 180.792 6.48697C176.583 6.48697 173.401 8.72829 172.424 12.6988H189.21Z"
        fill="white"
      />
      <path
        d="M11.0108 21.6361C3.51456 21.5866 -0.0447438 17.181 -0.000321819 10.7159C0.0441001 4.25079 3.67004 -0.050115 11.2162 -0.000552682H11.5938V4.78496H11.1329C6.45752 4.75192 4.54182 7.18598 4.51961 10.749C4.4974 14.3119 6.37423 16.768 11.0441 16.8066L25.9865 16.9112L25.9532 21.7463L11.0108 21.6361Z"
        fill="white"
      />
      <path
        d="M11.5941 30.2879V25.4528L23.0161 25.3317C27.686 25.2986 29.5684 22.8921 29.5461 19.274C29.5239 15.656 27.6027 13.299 22.9328 13.3321H11.5941V8.54656H22.844C30.3902 8.497 34.0161 12.8475 34.0605 19.263C34.1049 25.6786 30.5401 30.1337 23.0494 30.1833L11.5941 30.2879Z"
        fill="white"
      />
    </svg>
  );
}
