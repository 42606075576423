import { NetworkService } from '.';
import { IOptions } from './network';

class UserService {
  public async index(page = 1, query = '', role = 'member') {
    let response = null;

    if (role === 'admin') {
      response = await NetworkService.get(`/admin/users?page=${page}&query=${query}`);
    } else {
      response = await NetworkService.get(`/users?page=${page}&query=${query}`);
    }

    return response;
  }

  public async search(page: number, query: string) {
    return await NetworkService.get(`/users/search?page=${page}&query=${query}`);
  }

  public async get(id: string | number, options?: IOptions | undefined) {
   return await NetworkService.get(`/users/${id}`, options);
  }

  public async update(id: number, params: {}, role = 'member') {
    let response = null;

    if (role === 'admin') {
      response = await NetworkService.put(`/admin/users/${id}`, params);
    } else {
      response = await NetworkService.put(`/users/${id}`, params);
    }

    return response;
  }

  public async sendPasswordResetEmail(id: number) {
    return await NetworkService.post(`/admin/users/password_reset`, { id });
  }

  public async export() {
    return await NetworkService.get('/users/export');
  }

  public async import(file) {
    const formData = new FormData();
    formData.append('file', file);

    return await NetworkService.post(
      '/admin/users/import',
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  }
}
export default new UserService();
