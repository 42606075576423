import type { AppProps } from 'next/app';
import config from '@/config';
import NextNProgress from 'nextjs-progressbar';
import store from '@/redux/store';
import { AuthProvider } from '@/hooks';
import { Flip, ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { StripeWrapper } from '@/components/payment/stripe-wrapper';
import { GoogleAnalytics, usePageViews } from 'nextjs-google-analytics';
import { hotjar } from 'react-hotjar';
import { useEffect } from 'react';

import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-credit-cards/es/styles-compiled.css';

function MyApp({ Component, pageProps }: AppProps) {
  usePageViews();

  useEffect(() => {
    hotjar.initialize(config.HOTJAR_ID, 1)
    hotjar.identify(null, {})
  }, [])

  return (
    <Provider store={store}>
      <AuthProvider>
        <StripeWrapper>
          <NextNProgress
            color="#5A47FF"
            startPosition={0.3}
            stopDelayMs={200}
            height={6}
          />
          <ToastContainer autoClose={3000} transition={Flip} />
          <GoogleAnalytics />
          <Component {...pageProps} />
        </StripeWrapper>
      </AuthProvider>
    </Provider>
  );
}
export default MyApp;
