import { NetworkService } from ".";
import { IPayment } from '@/types';

class StripeService {
  public async getProducts(all = false) {
    let response = null;
    if (all) {
      response = await NetworkService.get(`/stripe/products?all=true`);
    } else {
      response = await NetworkService.get(`/stripe/products`);
    }

    return response;
  }

  public async getCards() {
    return NetworkService.get('/stripe/cards');
  }

  public async getPromoCode(promo: string) {
    const response = await NetworkService.get(`/stripe/promo_codes/${promo}`);

    return response;
  }

  public async savePaymentMethod(data: IPayment) {
    const response = await NetworkService.post(`/stripe/cards`, {
      paymentMethod: data
    });

    return response;
  }

  public async updatePaymentMethod({
    expMonth,
    expYear,
    name,
    id
  }: { expMonth: string, expYear: string, name: string, id: number }) {
    const response = await NetworkService.put(`/stripe/cards/${id}`, {
      paymentMethod: { expMonth, expYear, name }
    });

    return response;
  }
}

export default new StripeService();
