import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '@/types';
import { RootState } from '@/redux/store';
import { hotjar } from 'react-hotjar'
import AuthService from '@/services/auth';

interface IUserSlice {
  isLoading: boolean;
  isLoggedIn: boolean;
  isFailed: boolean;
  token?: string;
}
export type IUserSliceState = IUserSlice & Partial<IUser>

const initialState: IUserSliceState = {
  isLoading: true,
  isLoggedIn: false,
  isFailed: false,
  firstName: '',
  lastName: '',
  locationId: null,
  email: '',
  phone: '',
  token: '',
  address: { city: '', country: '', state: '', line1: '', line2: '', postalCode: '' },
  roles: [{ name: 'member' }],
};

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }: { email: string, password: string }, _) => {
    await AuthService.login(email, password);

    const user = AuthService.getUser();

    hotjar.identify(user.id.toString(), {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    });

    return {
      token: AuthService.getToken(),
      user: AuthService.getUser()
    }
  }
);

export const loginWithToken = createAsyncThunk(
  'user/loginWithToken',
  async (_) => {
    await AuthService.initializedUser();

    const user = AuthService.getUser();

    hotjar.identify(user.id.toString(), {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    });

    return {
      token: AuthService.getToken(),
      user: AuthService.getUser()
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading: (state) => {
      state.isFailed = false;
      state.isLoggedIn = false;
      state.isLoading = true;
    },
    loaded: (state, action: PayloadAction<IUser>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.roles = action.payload.roles;
      state.address = action.payload.address || initialState.address;
      state.locationId = action.payload.locationId || initialState.locationId;
      state.isFailed = false;
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    accountUpdated: (state, action: PayloadAction<IUser>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.address = action.payload.address || initialState.address;
    },
    unload: () => ({ ...initialState, isLoading: false })
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.firstName = action.payload.user.firstName;
      state.lastName = action.payload.user.lastName;
      state.phone = action.payload.user.phone;
      state.email = action.payload.user.email;
      state.roles = action.payload.user.roles;
      state.address = action.payload.user.address || initialState.address;
      state.locationId = action.payload.user.locationId || initialState.locationId;
      state.token = action.payload.token;
      state.isFailed = false;
      state.isLoggedIn = true;
      state.isLoading = false;
    })
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
      state.isFailed = true;
    })

    builder.addCase(loginWithToken.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(loginWithToken.fulfilled, (state, action) => {
      state.firstName = action.payload.user.firstName;
      state.lastName = action.payload.user.lastName;
      state.phone = action.payload.user.phone;
      state.email = action.payload.user.email;
      state.roles = action.payload.user.roles;
      state.address = action.payload.user.address || initialState.address;
      state.locationId = action.payload.user.locationId || initialState.locationId;
      state.token = action.payload.token;
      state.isFailed = false;
      state.isLoggedIn = true;
      state.isLoading = false;
    })
    builder.addCase(loginWithToken.rejected, (state, _) => {
      state.isLoading = false;
      state.isFailed = false;
    })
  }
});

export const { loaded, loading, unload, accountUpdated } = userSlice.actions;
export const userSelector = (state: RootState) => state.userSlice;
export default userSlice.reducer;
