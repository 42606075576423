import { useEffect, useState } from 'react';
import { IUser } from '@/types';
import { UserService } from '@/services';

const useUserSearch = ({ page = 1, query }) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IUser[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        await UserService.search(page, query).then((res) => {
          setUsers(res.data);
          setTotalPages(+res.headers['total-pages']);
          setCurrentPage(+res.headers['current-page']);
          setItemsPerPage(+res.headers['page-items']);
          setTotalCount(+res.headers['total-count']);
        })
      } finally {
        setLoading(false);
      }
    })();
  }, [page, query]);

  return [
    loading,
    users,
    currentPage,
    itemsPerPage,
    totalPages,
    totalCount
  ] as const;
}

export default useUserSearch;
