import config from '@/config';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';

export const StripeWrapper: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const stripePromise = useMemo(() =>
    loadStripe(config.STRIPE_PUBLISHABLE_KEY as string),
  /* eslint-disable react-hooks/exhaustive-deps */
  [config.STRIPE_PUBLISHABLE_KEY]);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
