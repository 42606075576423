import { NetworkService } from ".";

class PaymentService {
  private subscription: any | null = null;

  public async createSubscription(data: Object) {
    const response = NetworkService.post(`/cars`, { ...data });
    await this.getSubscription();
    return response;
  }

  public async createSubscriptionForCar(data: any) {
    const response = NetworkService.post(
      `/cars/${data.carId}/subscriptions`,
      { ...data }
    );
    await this.getSubscription();
    return response;
  }

  public async getPrice(priceId: string) {
    return NetworkService.get(`/plans/${priceId}`);
  }

  public async getUserSubscription() {
    const subscription = await NetworkService.get("/payment/subscription");
    this.subscription = subscription.data;
    return subscription;
  }

  public getSubscription() {
    return this.subscription;
  }

  public async updatePaymentMethod(
    carId: string | number,
    subscriptionId: string | number,
    paymentMethodId: string | number,
  ) {
    const response = NetworkService.put(
      `/cars/${carId}/subscriptions/${subscriptionId}/set_payment_method`,
      { subscription: { paymentMethod: { id: paymentMethodId } } }
    );
    await this.getSubscription();
    return response;
  }

  public async changeCarSubscription(
    subscriptionId: number,
    priceId: string,
    carId: string,
    paymentMethod,
  ) {
    const response = NetworkService.put(
      `/cars/${carId}/subscriptions/${subscriptionId}`,
      { subscription: { priceId, paymentMethod } }
    );
    await this.getSubscription();
    return response;
  }

  public async payInvoiceWithCard(
    carId: string | number,
    subscriptionId: string | number,
    paymentMethodId: string | number,
  ) {
    const response = NetworkService.put(
      `/cars/${carId}/subscriptions/${subscriptionId}/pay_invoice_with_card`,
      { subscription: { paymentMethod: { id: paymentMethodId } } }
    );
    await this.getSubscription();
    return response;
  }
}

export default new PaymentService();
