import { IAddress, IUser } from "@/types";
import { NetworkService } from ".";
import config from '@/config';
import store from '@/redux/store';
import parsePhoneNumber from 'libphonenumber-js'

class AuthService {
  private user: IUser | null = null;
  public storage_key: string = config.AUTH_STORAGE_KEY;

  setUser(user: IUser | null) {
    this.user = user;
  }

  public getUser() {
    return this.user;
  }
  setToken(token: string) {
    localStorage.setItem(this.storage_key, token);
  }

  clearToken() {
    localStorage.removeItem(this.storage_key);
  }

  getToken() {
    const state = store.getState();
    if (state.userSlice.token.length) {
      return state.userSlice.token;
    }

    return localStorage.getItem(this.storage_key);
  }

  public removeToken() {
    localStorage.removeItem(this.storage_key);
  }

  public async logout() {
    this.removeToken();
    this.setUser(null);
  }

  public async login(email: string, password: string) {
    await NetworkService.post("/users/sign_in", {
      user: { email, password  }
    }).then(({ data, headers }) => {
      this.setUser(data);
      this.setToken(headers.authorization.replace('Bearer ', ''));
    }).catch(() => {
      this.clearToken();
      throw new Error('Unable to authenticate');
    });

    return this.getToken();
  }

  public async validate(user: IUser) {
    const response = await NetworkService.post("/users/validate", user);

    return response;
  }

  public async initializedUser() {
    const { data } = await NetworkService.get("/users/me");
    if (data.phone) {
      const phone = parsePhoneNumber(data.phone);
      data.phone = phone.formatNational();
    }
    this.setUser(data);

    return this.getUser();
  }

  public async signup(user: IUser) {
    const response = await NetworkService.post("/users", user);

    if (!response.data.errors) {
      this.setUser(response.data);
      this.setToken(response.headers.authorization.replace('Bearer ', ''));
    }

    return response;
  }

  public async updateAccount(user: IUser) {
    const response = await NetworkService.put("/users/me", { account: user });
    if (response.data.phone) {
      const phone = parsePhoneNumber(response.data.phone);
      response.data.phone = phone.formatNational();
    }
    this.setUser(response.data);

    return response;
  }

  public async updateAddress(address: IAddress) {
    const response = await NetworkService.post('/users/address', {
      user_address: address
    });

    return response;
  }

  public async updatePassword(password: string, password2: string) {
    const response = await NetworkService.put(
      "/users/me/update_password",
      { account: { password, password2 } }
    );

    return response;
  }

  public async forgotPassword(email: string) {
    const response = await NetworkService.post("/users/password", {
      user: { email: email }
    });

    return response;
  }

  public async resetPassword(token: string, password: string, passwordConfirmation: string) {
    const response = await NetworkService.put("/users/password", {
      user: {
        reset_password_token: token,
        password,
        passwordConfirmation,
      }
    });

    return response;
  }
}

export default new AuthService();
