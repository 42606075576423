const config = {
  get APP_URL() {
    return process.env.NEXT_PUBLIC_APP_URL;

  },
  get BACKEND_API() {
    return process.env.BACKEND_URL || process.env.NEXT_PUBLIC_BACKEND_URL || '';
  },

  get HOTJAR_ID() {
    return +process.env.NEXT_PUBLIC_HOTJAR_ID
  },

  get STRIPE_SECRET_KEY() {
    return process.env.NEXT_PUBLIC_STRIPE_SECRET_KEY;
  },

  get STRIPE_PUBLISHABLE_KEY() {
    return process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '';
  },

  get EMAIL_USERNAME() {
    return process.env.EMAIL_USERNAME || '';
  },

  get EMAIL_PASSWORD() {
    return process.env.EMAIL_PASSWORD || '';
  },

  get AUTH_STORAGE_KEY() {
    return process.env.NEXT_PUBLIC_RYNSE_AUTH_TOKEN || 'rynse_auth_token'
  },
  get LEGACY_PLAN_ID() {
    return 'price_1K9BJdHZaSBcFk7iIpqIqtjv';
  },
  get ACTIVE_PLANS() {
    return this.PLANS.filter((plan) => (
      plan.priceId !== 'price_1K9BJdHZaSBcFk7iIpqIqtjv' &&
        plan.priceId !== 'price_1J7ZoyHZaSBcFk7ikIoR5rrI')
    )
  },
  get PLANS() {
    return [
      {
        "id": "legacy",
        "description": 'Legacy product description',
        "currency": "usd",
        "name": "Legacy",
        "recurring": {
          "interval": "month",
          "interval_count": 1,
          "usage_type": "licensed"
        },
        "priceId": "price_1K9BJdHZaSBcFk7iIpqIqtjv",
        "unitAmount": 1999,
      },
      {
        "id": "legacy",
        "description": 'Legacy product description',
        "currency": "usd",
        "name": "Legacy",
        "recurring": {
          "interval": "month",
          "interval_count": 1,
          "usage_type": "licensed"
        },
        "priceId": "price_1J7ZoyHZaSBcFk7ikIoR5rrI",
        "unitAmount": 1999,
      },
      {
        "id": "cleanse",
        "description": 'Cleanse product description',
        "currency": "usd",
        "name": "Cleanse",
        "recurring": {
          "interval": "month",
          "interval_count": 1,
          "usage_type": "licensed"
        },
        "priceId": "price_1K4sSXHZaSBcFk7icwL51qrA",
        "unitAmount": 1999,
      },
      {
        "id": "sparkle",
        "description": 'Sparkle product description',
        "currency": "usd",
        "name": "Sparkle",
        "recurring": {
          "interval": "month",
          "interval_count": 1,
          "usage_type": "licensed"
        },
        "priceId": "price_1K4sULHZaSBcFk7isn77KiV3",
        "unitAmount": 2499,
      },
      {
        "id": "transform",
        "description": 'Transform product description',
        "currency": "usd",
        "name": "Transform",
        "recurring": {
          "interval": "month",
          "interval_count": 1,
          "usage_type": "licensed"
        },
        "priceId": "price_1K4sRQHZaSBcFk7iSgAOW0DN",
        "unitAmount": 2999,
      }
    ];
  }
};

export default config;
