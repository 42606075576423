import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingScreen } from '@/components/layout/loading';
import { loginWithToken } from '@/redux/slices/userSlice';

export const AuthProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const dispatch = useDispatch();
  const [loginAttempted, setLoginAttempted] = useState(false);

  useEffect(() => {
    try {
      dispatch(loginWithToken());
      setLoginAttempted(true);
    } catch (error: any) {
      console.log(error.message);
    }
  }, [dispatch]);

  return loginAttempted ? <>{children}</> : <LoadingScreen />
};
